import { Box, Typography } from '@mui/material'
import { useEffect } from 'react'
import CheckActivationActions from '~/src/components/auth/activation/CheckActivationActions'
import FormError from '~/src/components/error/FormError'
import Title from '~/src/components/mui-wrappers/Title'
import useCheckActivation from '~/src/hooks/auth/useCheckActivation'
import { useAsyncEffect } from '~/src/hooks/useAsyncEffect'
import { useEoValue } from '~/src/hooks/useEoState'
import { useTranslations } from '~/src/hooks/useTranslations'
import { Player } from '@lottiefiles/react-lottie-player'
import { LAYOUT_STATE } from '~/src/stores/layout'
import { FALLBACK_PAGE_SELECTOR } from '~/src/stores/router'
import { useRouterPush } from '~/src/hooks/router/useRouterPush'
import { useFirmPathResolver } from '~/src/hooks/router/useFirmPathResolver'

const CheckActivation = () => {
  const { t } = useTranslations()
  const { push } = useRouterPush()
  const { resolve } = useFirmPathResolver()
  const { isMobile } = useEoValue(LAYOUT_STATE)
  const { check: doCheckActivation, activated, error } = useCheckActivation()
  const fallbackPage = useEoValue(FALLBACK_PAGE_SELECTOR)

  const handleCheckActivation = () => {
    doCheckActivation(true)
  }

  useEffect(() => {
    const interval = setInterval(() => {
      doCheckActivation(false)
    }, 5000)

    return () => {
      clearInterval(interval)
    }
  }, [ doCheckActivation ])

  useAsyncEffect(async () => {
    if (!activated) {
      return
    }

    const response: any = true

    if (response) {
      push(resolve(fallbackPage))
    }
  }, [ activated ])

  return (
    <Box
      id='check-activation'
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
        ['h5, div.check-activation-actions']: {
          alignSelf: isMobile ? 'flex-start' : 'center'
        },
        'div.check-activation-actions': {
          '> button': {
            width: '100%'
          }
        }
      }}
    >
      <Player
        src='/images/lotties/register-email-verification.json'
        autoplay
        loop
        style={{ width: '100%', height: '45vh' }}
      />

      {error && <FormError>{error}</FormError>}

      <Title variant='h5'>{t('auth.checkActivation.content.title')}</Title>
      <Typography>{t('auth.checkActivation.content.subTitle')}</Typography>

      <CheckActivationActions onCheckActivation={handleCheckActivation}/>
    </Box>
  )
}

export default CheckActivation
